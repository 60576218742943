<template>
  <div>
    <div class="flix-alert flix-alert-success"><div><h3 class="flix-html-h3 flix-text-success">{{ amount }} {{ $t('message.credit') }}</h3>
      {{ $t('message.successfullSaved') }}</div></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    amount: Number,
    price: Object
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
